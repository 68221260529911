import FormFieldsContainer from '../../../common/components/containers/forms/FormFieldsContainer';
import WhiteCardContainer from '../../../common/components/containers/forms/WhiteCardContainer';
import ProfileFormHeader from '../ProfileFormHeader/ProfileFormHeader';
import useAppointmentHistory from './hooks/useAppointmentHistory';
import HalfRow from '../../../common/components/formElements/HalfRow';
import SimpleDataTable from './components/simpleDataTable/SimpleDataTable';
import { useState } from 'react';
import HistoryAppointmentDetails from './components/appointmentDetails/HistoryAppointmentDetails';
import citasHeaderIcon from '../../../images/citasHeaderIcon.png'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
const appointmentStateOptions = [
    { id: 2, value: 'pendientes', label: 'Pendientes' },
    { id: 1, value: 'finalizadas', label: 'Finalizadas' },
    { id: 3, value: 'cancelada', label: 'Cancelada' }

];

export default function AppointmentHistory() {
    const { completedTableInstance, isLoading, pendingTableInstance, _closeAppointmentView, selectedAppointment, onSuccessfulSave,cancelledTableInstance } =
        useAppointmentHistory();
    const [selectedTable, setSelectedTable] = useState('pendientes');



    return (
        <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-10 side-bar-right bg-dl-screen-lightblue">
            {/*onSubmit={handleSubmit(saveEducation)}*/}
            <WhiteCardContainer>
                {JSON.stringify(selectedAppointment) === '{}' && (
                    <FormFieldsContainer>
                        <div className="calender-show-date mb-10">
                            <figure>
                                {/* <img class="w-16 pr-2" src={citasHeaderIcon} alt="" /> */}
                                <svg width="76" height="67" viewBox="0 0 76 67" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M67.46 12H62V2.94C62 1.56 60.88 0.44 59.5 0.44C58.12 0.44 57 1.56 57 2.94V12H19V2.5C19 1.12 17.88 0 16.5 0C15.12 0 14 1.12 14 2.5V12H8.54C3.83 12 0 15.95 0 20.8V58.2C0 63.05 3.83 67 8.54 67H67.45C72.16 67 75.99 63.05 75.99 58.2V20.8C76 15.95 72.17 12 67.46 12ZM8.54 17H67.45C69.4 17 70.99 18.7 70.99 20.8V27.5H5V20.8C5 18.7 6.59 17 8.54 17ZM67.46 62H8.54C6.59 62 5 60.3 5 58.2V32.5H71V58.2C71 60.3 69.41 62 67.46 62Z" fill="#005CB9"></path><path d="M33.0006 54C32.2106 54 31.4406 53.63 30.9506 52.93L26.4506 46.51C25.6606 45.38 25.9306 43.82 27.0606 43.03C28.1906 42.24 29.7506 42.51 30.5406 43.64L33.6506 48.08L46.1306 39.93C47.2906 39.18 48.8406 39.5 49.5906 40.66C50.3406 41.82 50.0206 43.37 48.8606 44.12L34.3606 53.59C33.9406 53.87 33.4706 54 33.0006 54Z" fill="#005CB9"></path></svg>
                            </figure>
                            <ProfileFormHeader
                                title={'Historial'}
                                initialText={'Utiliza las opciones para filtrar los datos'}
                            />
                        </div>

                        <div className='history-name mb-8'>
                            <h4>Historial</h4>
                            <p>Utiliza las opciones para filtrar los datos</p>
                        </div>
                        <div className="grid grid-cols-6 gap-6 select-min-w similer-select mb-8">
                            <HalfRow>
                                <label htmlFor={'viewAppointments'} className="block form-label mb-3">
                                    Estado de Citas
                                </label>
                                <select
                                    id={'viewAppointments'}
                                    name={'viewAppointments'}
                                    value={selectedTable}
                                    onChange={(e) => setSelectedTable(e.target.value)}
                                    className="form-control min-w"
                                >
                                    {appointmentStateOptions &&
                                        appointmentStateOptions.map((opt) => {
                                            return (
                                                <option key={opt.label} value={opt.value}>
                                                    {opt.label}
                                                </option>
                                            );
                                        })}
                                </select>
                            </HalfRow>
                        </div>
                    </FormFieldsContainer>
                )}
                {!isLoading &&
                    completedTableInstance &&
                    selectedTable === 'finalizadas' &&
                    JSON.stringify(selectedAppointment) === '{}' && (
                        <SimpleDataTable tableInstance={completedTableInstance} />
                    )}

                {!isLoading &&
                    pendingTableInstance &&
                    selectedTable === 'pendientes' &&
                    JSON.stringify(selectedAppointment) === '{}' && (
                        <SimpleDataTable tableInstance={pendingTableInstance} />
                    )}
                {!isLoading &&
                    cancelledTableInstance &&
                    selectedTable === 'cancelada' &&
                    JSON.stringify(selectedAppointment) === '{}' && (
                        <SimpleDataTable tableInstance={cancelledTableInstance} />
                    )}

                {!isLoading && JSON.stringify(selectedAppointment) !== '{}' && (
                    <HistoryAppointmentDetails
                        appointment={selectedAppointment}
                        onCancel={_closeAppointmentView}
                        selectionMode={'viewOnly'}
                        onSuccessfulSave={onSuccessfulSave}
                    />
                )}
            </WhiteCardContainer>
        </div>
    );
}
