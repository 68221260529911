import { useState } from 'react';
import ProfileFormHeader from '../../../ProfileFormHeader/ProfileFormHeader';
import HalfRow from '../../../../../common/components/formElements/HalfRow';
import FormInputField from '../../../../../common/components/formElements/FormInputField';
import { CalendarIcon, MailIcon, PhoneIcon, UsersIcon, ClockIcon } from '@heroicons/react/outline';
import FullRow from '../../../../../common/components/formElements/FullRow';
import FormTextArea from '../../../../../common/components/formElements/FormTextArea';
import useAppointmentDetails from './hooks/useAppointmentDetails';
import CustomRedAlert from '../../../../../common/components/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../../common/components/customSuccessModal/CustomSuccessModal';
import { useLocation } from 'react-router-dom';
import FormSelectDrop from '../../../../../common/components/formElements/FormSelectDrop';

export default function HistoryAppointmentDetails(props) {
    const { appointment, onCancel, selectionMode, onSuccessfulSave } = props;

    const [openReturnAlert, setOpenReturnAlert] = useState(false);


    const {
        handleSubmit,
        getValues,
        register,
        errors,
        isDirty,
        watch,
        closeErrorMessage,
        onCloseSuccessMessage,
        showSuccessModal,
        showErrorModal,
        errorBody,
        successBody,
        onSaveSubmit,
        statusOptions
    } = useAppointmentDetails(appointment, onSuccessfulSave, selectionMode, onCancel);

    if (!selectionMode) {
        return null;
    }

    const _onReturnToAppointments = () => {
        if (isDirty) {
            setOpenReturnAlert(true);
        } else {
            onCancel();
        }
    };

    const _onConfirmCancelAppointment = () => {
        setOpenReturnAlert(false);
        onCancel();
    };

    const _onCancelConfirmationForClosing = () => {
        setOpenReturnAlert(false);
        onCancel()
    };




   

    return (
        <form onSubmit={handleSubmit(onSaveSubmit)}>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                    {selectionMode !== 'viewOnly' && (
                        <ProfileFormHeader
                            title={selectionMode === 'complete' ? 'Completar Cita' : 'Solicitar Reprogramación'}
                            textColor={selectionMode === 'reschedule' ? 'text-dl-red' : null}
                        />
                    )}
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-dl-secondary">Detalles del paciente</h3>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                        <HalfRow>
                            <FormInputField
                                identifier="userFullName"
                                label="Nombre del Paciente"
                                iconComponent={<UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('userFullName', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="birthDate"
                                label="Fecha de Nacimiento"
                                iconComponent={<CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('birthDate', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="appDate"
                                label="Fecha de cita"
                                iconComponent={<CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('appDate', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="appTime"
                                label="Hora de cita"
                                iconComponent={<ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('appTime', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="userPhone"
                                label="Teléfono"
                                iconComponent={<PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('userPhone', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="userEmail"
                                label="Correo Electrónico"
                                iconComponent={<MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('userEmail', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>
                        <FullRow>
                            <FormInputField
                                identifier="motiveName"
                                label="Motivo de consulta"
                                type="string"
                                registration={register('motiveName', {
                                    required: true
                                })}
                                disabled
                            />
                            {errors.phone ? (
                                <p className="text-red-700 font-light">Este campo no puede ser vacío</p>
                            ) : null}
                        </FullRow>
                        <FullRow>
                            <FormTextArea
                                label={'Notas del paciente'}
                                identifier={'userNotes'}
                                rows={3}
                                registration={register('userNotes')}
                                disabled
                            />
                        </FullRow>
                        <FullRow>
                            <FormTextArea
                                label={
                                    selectionMode === 'completion' || selectionMode === 'viewOnly'
                                        ? 'Notas del Doctor'
                                        : 'Mensaje al usuario'
                                }
                                placeholder={
                                    selectionMode === 'reschedule'
                                        ? `Ej. Por motivos de viaje no podre atenderle, por favor reprograme para después del 20 de octubre. Muchas gracias.`
                                        : null
                                }
                                identifier={'doctorNotes'}
                                rows={3}
                                registration={register('doctorNotes', { required: false })}
                                // helperText={'Requerido para marcar como completado o reprogramar'}
                                errorMessage={errors.doctorNotes ? 'Este campo es requerido' : null}
                                disabled={selectionMode === 'viewOnly'}
                            />
                        </FullRow>
                        <FullRow>
                            {/* <FormInputField
                                identifier="statusName"
                                label="Estado actual de cita"
                                type="string"
                                registration={register('statusName', {
                                    required: true
                                })}
                                disabled
                            /> */}
                            {/* <div>
                                <label htmlFor={"statusName"}>Estado actual de cita</label>
                                <div className="relative rounded-md ">
                                    <select
                                        id={"statusName"}
                                        className='block w-full border border-gray-300 rounded-full  py-2 px-3 focus:outline-none focus:ring-dl-primary-400 focus:border-dl-primary-400 sm:text-sm font-normal h-10'
                                        {...register('statusName', { required: true })}
                                    // defaultValue=""
                                    >
                                        <option value="" disabled>Select an option</option>
                                        {statusOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div> */}
                            {/* <FormSelectDrop
                                label={'Estado actual de cita'}
                                identifier={'idAppointmentStatus'}
                                options={statusOptions}
                                defaultValue={getValues()?.idAppointmentStatus}
                                helperText={'Requerido'}
                                registration={register('idAppointmentStatus', { required: true })}
                                errorMessage={errors.idAppointmentStatus ? 'Este campo es requerido' : null}
                            />
                        </FullRow> */}
                            <label htmlFor="idAppointmentStatus" className="block text-sm font-medium text-gray-700">
                                Estado actual de cita
                            </label>
                            <select
                                id="idAppointmentStatus"
                                name="idAppointmentStatus"
                                value={watch('idAppointmentStatus')}
                                {...register('idAppointmentStatus', { required: true })}
                                className="mt-1 block w-full bg-white border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-500 font-normal focus:border-dl-primary-500 sm:text-sm"
                                disabled={appointment.idAppointmentStatus != 1}
                            >
                                <option key="empty-select-value" value="">
                                    --- Seleccione --
                                </option>
                                {statusOptions &&
                                    statusOptions.map((opt) => (
                                        <option key={opt.value} value={opt.value}>
                                            {opt.label}
                                        </option>
                                    ))}
                            </select>
                            {errors.idAppointmentStatus && (
                                <p className="mt-2 text-sm text-red-700 text-normal">Este campo es requerido</p>
                            )}

                        </FullRow>
                    </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    {/*<button
                        type="button"
                        onClick={onCancel}
                        className="bg-dl-red border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-red"
                    >
                        Cerrar
                    </button>*/}
                    <button
                        type="button"
                        onClick={_onReturnToAppointments}
                        className="mr-2 rounded-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dl-accent focus:ring-offset-2"
                    >
                        Regresar
                    </button>
                    {appointment.idAppointmentStatus == 1 ?
                        <button
                            type="submit"
                            className="bg-dl-red border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-[#f16782] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-accent false"
                        >
                            Ahorrar
                        </button>
                        : ""
                    }
                    {/* {selectionMode === 'complete' && (
                        <button
                            type="submit"
                            className="bg-dl-primary-900 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
                        >
                            Completar Cita
                        </button>
                    )}

                    {selectionMode === 'reschedule' && (
                        <button
                            type="submit"
                            className="bg-dl-red border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-[#f16782] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-accent"
                        >
                            Reprogramar Cita
                        </button>
                    )} */}
                </div>
            </div >

            {openReturnAlert && (
                <CustomRedAlert
                    open={openReturnAlert}
                    title={'¿Desea descartar los cambios?'}
                    bodyText={'La cita ha sido modificada, por favor confirme que desea salir de esta vista.'}
                    onClose={_onCancelConfirmationForClosing}
                    mainActionText={'Salir'}
                    onMainAction={_onConfirmCancelAppointment}
                    secondaryActionText={'Cancelar'}
                    onSecondaryAction={_onCancelConfirmationForClosing}
                />
            )
            }

            {
                showErrorModal && (
                    <CustomRedAlert
                        open={showErrorModal}
                        title={'Ocurrió un error.'}
                        bodyText={errorBody}
                        onClose={closeErrorMessage}
                    />
                )
            }

            {
                showSuccessModal && (
                    <CustomSuccessModal
                        title={'Registro actualizado con éxito'}
                        bodyText={successBody}
                        buttonText={'Aceptar'}
                        buttonAction={onCloseSuccessMessage}
                        open={showSuccessModal}
                        onClose={onCloseSuccessMessage}
                    />
                )
            }
        </form >
    );
}
