import React, { useEffect, useState } from 'react';
import getAppointmentHistory from '../../api/getAppointmentHistory';
import { Link } from 'react-router-dom';
import { handleImageError } from '../../../common/helpers/helper';
import ReactPaginate from 'react-paginate';

const CompletedAppointments = () => {
    const [completedAppointedDoctors, setCompletedAppointedDoctors] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [appointmentsPerPage] = useState(24);

    const completedAppointmentHistory = async () => {
        const getHistory = await getAppointmentHistory(setErrorMessage, 2);
        setCompletedAppointedDoctors(getHistory?.completedAppointmentDoctors || []);
        setErrorMessage('No se pudo completar la solicitud de citas. Inténtelo de nuevo.');
    };

    useEffect(() => {
        completedAppointmentHistory();
    }, []);


    useEffect(() => {
        completedAppointmentHistory();
    }, []);

    const indexOfLastAppointment = (currentPage + 1) * appointmentsPerPage;
    const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
    const currentAppointments = completedAppointedDoctors.slice(indexOfFirstAppointment, indexOfLastAppointment);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    return (
        <div className="bg-dl-screen-lightblue py-12 lg:px-8 mx-auto px-4 relative sm:px-6">
            <div className='wrapper-box'>
                <div className='bg-white rounded-lg px-10 pt-12 pb-10'>
                    <div className=' doctor-list mis-doctores-list'>
                        <div className="mis-doctores-header" style={{ justifyContent: "center" }}>
                            <h2 className='common-title pb-5'>Mis doctores</h2>
                        </div>
                        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8' style={{ minHeight: "20vh" }}>
                            {currentAppointments.map((appointment, index) => (
                                <Link
                                    key={index}
                                    to={`/doctor-results/${appointment?.idDoctorUser}/${appointment?.doctorFullName?.replace(/ /g, '-')}`}
                                >
                                    <div className="doctor-card">
                                        <img
                                            src={`${process.env.REACT_APP_API_SERVER}${appointment?.doctor_image}`}
                                            alt={`Dr. ${appointment?.doctorFullName}`}
                                            className="avatar"
                                            onError={handleImageError}
                                        />
                                        <div className="info">
                                            <span className="name">Dr. {appointment?.doctorFullName}</span>
                                            <span className="specialty">{appointment?.speciality}</span>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>


                    </div>

                    {/* {completedAppointedDoctors?.length > appointmentsPerPage &&
                        ( */}
                    <div className="pagination  block " style={{ marginTop: '50px' }}>
                        <ReactPaginate
                            previousLabel={"Previo"}
                            nextLabel={"Próxima"}
                            breakLabel={"..."}
                            pageCount={Math.ceil(completedAppointedDoctors.length / appointmentsPerPage)}
                            marginPagesDisplayed={1} // Displays 1 page on either side of the current page
                            pageRangeDisplayed={3} // Number of pages to display around the current page
                            onPageChange={handlePageClick}
                            containerClassName={"pagination flex flex-wrap justify-center items-center mt-8"}
                            pageClassName={"m-1"}
                            pageLinkClassName={"px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded "}
                            previousClassName={"m-1"}
                            previousLinkClassName={"px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded "}
                            nextClassName={"m-1"}
                            nextLinkClassName={"px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded "}
                            breakClassName={"m-1"}
                            breakLinkClassName={"px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded "}
                            activeClassName={"active"}
                            activeLinkClassName={"bg-[#215cb9] text-white border-[#215cb9]"} // Updated active color
                            disabledClassName={"opacity-50 cursor-not-allowed"}
                        />
                    </div>
                    {/* )} */}
                </div>
            </div>
        </div>
    );
};

export default CompletedAppointments;