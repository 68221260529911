import React from 'react';

const Terms = () => {
    return (
        <div className="static-page-des">
            <div className='wrapper-box'>
                <div className='bg-white rounded-lg px-10 pt-12 pb-10'>
                    <h2 className='text-center text-xl'><b>Terms and Conditions</b></h2>
                    <p>Esta página web “Doctolink” y la aplicación móvil “Doctolink App” son propiedad y está operado por Doctolink.</p>
                    <p>Estos Términos y la Política de privacidad juntos constituyen un acuerdo legal <b>(“Acuerdo”)</b> entre usted y Doctolink en relación con su visita al sitio web o aplicación y su uso de los Servicios (como se define a continuación).<br />
                        El Acuerdo se aplica a usted para lo siguiente:
                    </p>
                    <ol>
                        <li><b> NATURALEZA Y APLICABILIDAD DE LOS TÉRMINOS</b></li>
                    </ol>
                    <ul>
                        <li>Sea un médico o proveedor de atención médica (ya sea un profesional individual o una organización) o una institución similar que desee figurar en la lista, o que ya esté incluida, en el sitio web o aplicación, incluidos los asociados designados y autorizados de dichos profesionales o instituciones</li>
                        <li>Un paciente, sus representantes o afiliados, que buscan Profesionales a través del sitio web o en la aplicación.</li>
                        <li>De lo contrario, un usuario del sitio web.</li>
                        <li>
                            Este Acuerdo se aplica a los servicios puestos a disposición por Doctolink en el sitio web y app, que se ofrecen de forma gratuita a los Usuarios, incluidos los siguientes:
                            <ul>
                                <li>Para Usuarios: Facilidad para crear Cuentas, buscar Doctores por nombre, especialidad y área geográfica, o cualquier otro criterio que Doctolink pueda desarrollar y poner a disposición, y para hacer citas con los Doctores.</li>
                            </ul>
                        </li>
                        <li>
                            Este Acuerdo se aplica a los servicios puestos a disposición por Doctolink en el sitio web y app, que se ofrecen como servicio por suscripción a los Doctores, incluidos los siguientes:
                            <ul>
                                <li>Perfiles y detalles de contacto, que estarán disponibles a los demás Usuarios y visitantes del Sitio Web;</li>
                                <li>Agenda para citas compartida con los usuarios</li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        Los Servicios pueden cambiar de vez en cuando, a la sola discreción de Doctolink, y el Acuerdo se aplicará a su visita y uso del sitio web para aprovechar el Servicio, así como a toda la información proporcionada por usted en el sitio web en cualquier momento.
                    </p>
                    <p>
                        Este Acuerdo define los términos y condiciones bajo los cuales se le permite usar el sitio web y describe la manera en que trataremos su cuenta mientras esté registrado como miembro con nosotros.
                        Si tiene alguna pregunta sobre cualquier parte del Acuerdo, no dude en contactarnos en soporte@doctolink.com.
                    </p>
                    <p>
                        Al descargar o acceder al Sitio web para usar los Servicios, acepta irrevocablemente todas las condiciones estipuladas en este Acuerdo, los <b>Términos de servicio de suscripción</b> y <b>la Política de privacidad</b>, disponibles en el Sitio web, y acepta cumplirlos.
                        Este Acuerdo reemplaza todos los términos y condiciones orales y escritos anteriores (si corresponde) que se le comunicaron en relación con su uso del sitio web para aprovechar los Servicios.
                        Al hacer uso de cualquier Servicio, usted expresa su aceptación de los términos de este Acuerdo.
                    </p>
                    <p>
                        Nos reservamos el derecho de modificar o rescindir cualquier parte del Acuerdo por cualquier motivo y en cualquier momento, y dichas modificaciones le serán informadas por escrito.
                        Debe leer el Acuerdo a intervalos regulares. Su uso del sitio web después de cualquier modificación constituye su acuerdo de seguir y estar sujeto al Acuerdo así modificado.
                    </p>
                    <p>
                        Usted reconoce que estará obligado por este Acuerdo para hacer uso de cualquiera de los Servicios que ofrecemos. Si no está de acuerdo con alguna parte del acuerdo, no utilice el sitio web ni utilice ningún servicio.
                    </p>
                    Su acceso al uso del sitio web y los servicios será únicamente a discreción de Doctolink.

                    <p>
                        Para usar nuestra página web, APP y / o recibir nuestros servicios, debes tener al menos 18 años de edad, o la mayoría de edad legal en tu jurisdicción, y poseer la autoridad legal, el derecho y la libertad para participar en estos Términos como un acuerdo vinculante.
                        No tienes permitido utilizar esta página web y / o recibir servicios si hacerlo está prohibido en tu país o en virtud de cualquier ley o regulación aplicable a tu caso.
                    </p>
                    <p>
                        Los precios que cobramos por usar nuestros servicios / para nuestros productos se enumeran en la página web.
                        Nos reservamos el derecho de cambiar nuestros precios para los productos que se muestran en cualquier momento y de corregir los errores de precios que pueden ocurrir inadvertidamente.
                        Información adicional sobre precios e impuestos sobre las ventas está disponible en la página de pagos.
                    </p>
                    <p>
                        La tarifa por los servicios y cualquier otro cargo que pueda incurrir en relación con tu uso del servicio, como los impuestos y las posibles tarifas de transacción, se cobrarán mensualmente a tu método de pago.
                    </p>
                </div>
            </div>
        </div >
    );
};

export default Terms;
